import { Observable } from "../../../utils/observable";
import { CreateComboboxContainer, uniqueId } from "../utils";
export class Toggle {
    selected;
    onChange;
    constructor(selected, onChange) {
        this.selected = selected;
        this.onChange = onChange;
    }
    toggle() {
        this.onChange(this.selected.value === "true" ? "false" : "true");
    }
    render(result) {
        const expanded = Observable(false);
        const { container, leafContainer } = CreateComboboxContainer({
            label: result.label,
            onBlur: () => { },
        }, expanded);
        const button = document.createElement("button");
        button.classList.add("phd-facets-combobox-toggle-container");
        button.ariaSelected = "false";
        const id = uniqueId();
        const input = document.createElement("input");
        input.classList.add("phd-facets-combobox-toggle-checkbox");
        input.type = "checkbox";
        input.id = id;
        input.name = result.label;
        const label = document.createElement("label");
        label.classList.add("phd-facets-combobox-toggle-label");
        label.htmlFor = id;
        label.innerText = `${result.option}`;
        leafContainer.appendChild(button);
        button.appendChild(input);
        button.appendChild(label);
        button.addEventListener("click", () => this.toggle());
        this.selected.subscribeAndRun(function (val) {
            input.checked = val === "true";
            button.ariaSelected = `${val === "true"}`;
        });
        return container;
    }
}
